const NIVEA = {};

let data = {

}

NIVEA.overlays = {
  popup: {},
  init: function() {
    let t = this;
    $('.js-show-overlay').on('click', function(){
      let id = $(this).attr('data-show');
      t.show(id);
    });
    $('.js-close-btn, .js-overlay').on('click', function(e){
      e.preventDefault();
      t.hide();
    });
    t.initByHash();
  },
  initByHash: function() {
    let id = window.location.hash.slice(1);
    if(id.length > 0 && $("[data-popup="+id+"]").length) {
      this.show(id);
    }
  },
  show: function(id) {
    this.popup = $("[data-popup="+id+"]");
    $('body').addClass('blocked');
    $('.popups').css("display", "flex").hide().fadeIn(500); //кастыль для флекса
    $('.js-overlay').addClass('active');
    this.popup.fadeIn(500);
    this.popup.find('video')[0].play();
    window.location.hash = id;
    let gifs = $('.makeup__gif');
    gifs.map((i, item)=>{
      $(item).addClass('paused');
    })
  },
  hide: function() {
    this.popup.find('video')[0].pause();
    $('body').removeClass('blocked');
    $('.popups').fadeOut(500);
    $('.js-overlay').removeClass('active');
    $("[data-popup]").fadeOut(500);
    window.location.hash = '_'; //прыгало на начало страницы
    let gifs = $('.makeup__gif');
    gifs.map((i, item)=>{
      $(item).removeClass('paused');
    })
  }
}

NIVEA.showContent = {
  init: function() {
    let t = this;
    $('.js-left-products').on('click', function () {
      t.pauseVideo();
      $(this).addClass('hidden');
      $('.js-right-products').removeClass('hidden');
      $('.js-right-products').addClass('active');
      t.showSkinBlock();
    });

    let onScrollFunction = function() {
      if($(this).scrollTop() > $('.video-section__wrapper').height()) {
        t.pauseVideo()
      }
    }


    $('.skin-section, .makeup-section').scroll(onScrollFunction)
    $(window).scroll(onScrollFunction)

    $('.js-right-products').on('click', function () {
      t.pauseVideo();
      $(this).addClass('hidden');
      $('.js-left-products').removeClass('hidden');
      $('.js-left-products').addClass('active');
      t.showMakeBlock();
    });
  },
  showSkinBlock: function() {
    $('.makeup-section').removeClass('active');
    $('.skin-section').addClass('active');
    $('.choice-section__wrapper').addClass('active-left');
    $('html, body').scrollTop(0);
    $('.skin-section').scrollTop(0);
    $('.skin-section').find('.js-play-video').click();
    setTimeout(_=>{
      $('.choice-section__wrapper').hide();
    }, 1000);
  },
  showMakeBlock: function() {
    $('.skin-section').removeClass('active');
    $('.makeup-section').addClass('active');
    $('.choice-section__wrapper').addClass('active-right');
    $('html, body').scrollTop(0);
    $('.makeup-section').scrollTop(0);
    $('.makeup-section').find('.js-play-video').click();
    setTimeout(_=>{
      $('.choice-section__wrapper').hide();
    }, 1000);
  },
  pauseVideo: function() {
    let videos = $('video');
    videos.map((i, item) =>{
      item.pause();
    });
  }
}

NIVEA.productsAnimation = {
  init: function() {
    $('.js-left-products').mouseenter(function(){
      $('.flower').css({
        'transition': '0.5s',
        'transform': 'translate(-50px) rotate(10deg)'
      });
      $('.product-1').css({
        'transition': '0.5s',
        'transform': 'translate(-50px) rotate(10deg)'
      });
      $('.product-2').css({
        'transition': '0.7s',
        'transform': 'translate(-60px) rotate(20deg)'
      });
      // $('.product-3').css({
      //   'transition': '0.4s',
      //   'transform': 'translate(-40px) rotate(-10deg)'
      // });
      $('.grape-1').css({
        'transition': '0.7s',
        'transform': 'translate(-50px) rotate(10deg)'
      });
      $('.grape-2').css({
        'transition': '0.7s',
        'transform': 'translate(-80px) rotate(30deg)'
      });
      // $('.water').css({
      //   'transition': '0.5s',
      //   'transform': 'translate(-50px) rotate(-20deg)'
      // });
    });
    $('.js-left-products, .js-right-products').mouseleave(function(){
      $('.flower').css({
        'transition': '0.5s',
        'transform': 'translate(0px) rotate(0deg)'
      });
      $('.product-1').css({
        'transition': '0.5s',
        'transform': 'translate(0px) rotate(0deg)'
      });
      $('.product-2').css({
        'transition': '0.7s',
        'transform': 'translate(0px) rotate(0deg)'
      });
      $('.product-3').css({
        'transition': '0.4s',
        'transform': 'translate(0px) rotate(0deg)'
      });
      $('.grape-1').css({
        'transition': '0.6s',
        'transform': 'translate(0px) rotate(0deg)'
      });
      $('.grape-2').css({
        'transition': '0.6s',
        'transform': 'translate(0px) rotate(0deg)'
      });
      $('.water').css({
        'transition': '0.5s',
        'transform': 'translate(0px) rotate(0deg)'
      });
    });
    $('.js-right-products').mouseenter(function(){
      // $('.flower').css({
      //   'transition': '0.5s',
      //   'transform': 'translate(50px) rotate(-10deg)'
      // });
      // $('.product-1').css({
      //   'transition': '0.5s',
      //   'transform': 'translate(50px) rotate(-10deg)'
      // });
      // $('.product-2').css({
      //   'transition': '0.7s',
      //   'transform': 'translate(60px) rotate(-20deg)'
      // });
      $('.product-3').css({
        'transition': '0.4s',
        'transform': 'translate(40px) rotate(-10deg)'
      });
      $('.grape-1').css({
        'transition': '0.7s',
        'transform': 'translate(50px) rotate(-10deg)'
      });
      $('.grape-2').css({
        'transition': '0.7s',
        'transform': 'translate(80px) rotate(-30deg)'
      });
      $('.water').css({
        'transition': '0.5s',
        'transform': 'translate(50px) rotate(20deg)'
      });
    });
  }
}

NIVEA.slider = {
  init: function() {
    let width = window.innerWidth;
    if(width <= 1024) {
      $('.js-prodcut-slider').slick({
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        centerMode: true,
        infinity: false,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              variableWidth: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    } else {
      $('.js-prodcut-slider').slick({
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        infinity: false
      });
    }

  }
}

NIVEA.video = {
  init: function() {
    $('.js-play-video').on('click', function(){
      $(this).hide().next()[0].play();
      $('.video-section__video-wrapper').addClass('playing');
    });
    $('video').on('click', function(){
      let video = $(this)[0];
      if(video.paused) {
        video.play();
      } else {
        video.pause();
        $(this).prev().show();
        $('.video-section__video-wrapper').removeClass('playing');
      }
    });
  }
}

NIVEA.scroll = {
  init: function() {
    // $(".popup__content").mCustomScrollbar({
    //   mouseWheelPixels: 150,
    //   scrollInertia: 110
    // });
  }
}

NIVEA.share = {
  sharingPopupShow: function(clickedElem) {
      var network = clickedElem.attr('data-name'),
          networkUrl = clickedElem.attr('data-link'),
          pageUrl = window.location.origin,
          shareUrl = clickedElem.attr('data-url'),
          shareImg = clickedElem.attr('data-img'),
          text,
          url = '';

      if (shareUrl) {
          text = $("meta[property='og:title']").attr('content');
      } else {
          text = $("meta[property='og:description']").attr('content');
      }

      if (network == 'vk') {
          if (shareUrl) {
              url = networkUrl + pageUrl + shareUrl + '&noparse=true';
          } else {
              url = networkUrl + pageUrl + '/&noparse=true';
          }
      }
      if (network == 'tw') {
          if (shareUrl) {
              pageUrl = text + ': ' + pageUrl + shareUrl;
              url = networkUrl + pageUrl;
          } else {
              pageUrl = text + ': ' + pageUrl;
              url = networkUrl + pageUrl;
          }
      } else if (network == 'fb') {
          if (shareUrl) {
              url = networkUrl + pageUrl + shareUrl;
          } else {
              url = networkUrl + pageUrl;
          }
      } else if (network == 'ok') {
          if (shareUrl) {
              url = networkUrl + pageUrl + shareUrl;
          } else {
              url = networkUrl + pageUrl;
          }
      }

      window.open(url, '', 'toolbar=0,status=0,width=626,height=436');
  },

  init: function() {
      $('.js-share').on('click', function(e) {
          e.preventDefault();
          var clickedElem = $(this);
          NIVEA.share.sharingPopupShow(clickedElem);
      });
  }
}

$(document).ready(function() {
  NIVEA.slider.init();
  NIVEA.productsAnimation.init();
  NIVEA.showContent.init();
  NIVEA.overlays.init();
  NIVEA.video.init();
  NIVEA.scroll.init();
  NIVEA.share.init();
});


$(document).ready(function() {
  $('.preloader').fadeOut();
})
